import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  type ReactNode,
} from 'react';
import type { ContactFormFields } from '@transcend-io/contact-form-schema';

import { cn } from '#/lib/utils';

import { ChiliPiperScript } from './script';
import { emptyDomContainer, triggerSchedulingModal } from './trigger';

export enum SchedulingStateEnum {
  // No action has been taken
  IDLE = 'idle',
  // ChiliPiper is checking if lead needs routing or is disqualified
  ROUTING = 'routing',
  // Routing complete
  COMPLETE = 'complete',
  // Modal has been closed
  CLOSED = 'closed',
}

type SchedulingState = {
  status: SchedulingStateEnum;
  showModal: boolean;
};

interface ChiliPiperContextType {
  schedulingState: SchedulingState;
  setSchedulingState: React.Dispatch<React.SetStateAction<SchedulingState>>;
  formContainerId: string | undefined;
  initSchedulingModal: (fields: ContactFormFields) => void;
  waitForSchedulingLoad: () => Promise<void>;
}

const ChiliPiperContext = createContext<ChiliPiperContextType | undefined>(
  undefined,
);

interface DeferredPromise {
  resolve: () => void;
  promise: Promise<void>;
}

function createDeferredPromise(): DeferredPromise {
  let resolve!: () => void;
  const promise = new Promise<void>((r) => {
    resolve = r;
  });
  return { resolve, promise };
}

export function ChiliPiperProvider({
  children,
  enableScheduling,
}: {
  children: ReactNode;
  enableScheduling: boolean;
}) {
  const [schedulingState, setSchedulingState] = useState<SchedulingState>({
    status: SchedulingStateEnum.IDLE,
    showModal: false,
  });

  const [formContainerId, setFormContainerId] = useState<string | undefined>(
    undefined,
  );

  const schedulingPromiseRef = useRef<DeferredPromise>();

  // Generate unique form container ID on mount
  useEffect(() => {
    setFormContainerId('form_' + Math.random().toString(36).substring(2, 15));
  }, []);

  const handleRouted = () => {
    setSchedulingState({
      status: SchedulingStateEnum.COMPLETE,
      showModal: true,
    });
    schedulingPromiseRef.current?.resolve();
  };

  const handleDisqualified = () => {
    setSchedulingState({
      status: SchedulingStateEnum.COMPLETE,
      showModal: false,
    });
  };

  const handleCloseModal = () => {
    emptyDomContainer(formContainerId);
    setSchedulingState({
      status: SchedulingStateEnum.CLOSED,
      showModal: false,
    });
  };

  const initSchedulingModal = (fields: ContactFormFields) => {
    schedulingPromiseRef.current = createDeferredPromise();
    setSchedulingState((prev) => ({
      ...prev,
      status: SchedulingStateEnum.ROUTING,
    }));
    triggerSchedulingModal({
      fields,
      domElementId: `#${formContainerId}`,
      onClose: handleCloseModal,
      onDisqualified: handleDisqualified,
      onRouted: handleRouted,
    });
  };

  /**
   * Wait for ChiliPiper to complete loading the modal:
   *
   * - Either routing or disqualifying a lead
   * - or timing out
   */
  function waitForSchedulingLoad(): Promise<void> {
    if (!schedulingPromiseRef.current?.promise) {
      return Promise.resolve();
    }

    // This will either resolve when ChiliPiper has routed or disqualified a lead or after 3000ms
    // when this resolves, the default form success state will be shown,
    // if ChiliPiper then routes the lead after our timeout the modal will be shown on top of the form
    return Promise.race([
      schedulingPromiseRef.current.promise,
      new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 3000);
      }),
    ]);
  }

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      schedulingPromiseRef.current = undefined;
    };
  }, []);

  return (
    <ChiliPiperContext.Provider
      value={{
        schedulingState,
        setSchedulingState,
        formContainerId,
        initSchedulingModal,
        waitForSchedulingLoad,
      }}
    >
      {enableScheduling && (
        <>
          <ChiliPiperScript />
          <ChiliPiperModalContainer />
        </>
      )}
      {children}
    </ChiliPiperContext.Provider>
  );
}

export function useChiliPiperModal() {
  const context = useContext(ChiliPiperContext);
  if (context === undefined) {
    throw new Error('useChiliPiper must be used within a ChiliPiperProvider');
  }
  return context;
}

export function ChiliPiperModalContainer() {
  const { schedulingState, formContainerId } = useChiliPiperModal();

  return (
    <div
      data-chili-piper-container
      id={formContainerId}
      className={cn(
        'h-full w-full fixed inset-0 transition-opacity duration-500 ease-in-out z-20',
        schedulingState.showModal
          ? 'opacity-100'
          : 'opacity-0 pointer-events-none',
      )}
    />
  );
}
