import { ContactFormFields } from '@transcend-io/contact-form-schema';
import { cleanEmail } from '@transcend-io/segment/core';

export const emptyDomContainer = (domElement?: string) => {
  if (domElement) {
    const element = document.querySelector(domElement);
    if (element) element.innerHTML = '';
  }
};

type TriggerSchedulingModalProps = {
  fields: ContactFormFields;
  domElementId: `#${string}`;
  onClose: () => void;
  onRouted: () => void;
  onDisqualified: () => void;
};

/**
 * Submission handler for ChiliPiper scheduling modal
 *
 * @see https://help.chilipiper.com/hc/en-us/articles/360053800373-About-Concierge-Snippet-and-JS-API
 */
export function triggerSchedulingModal({
  fields,
  domElementId,
  onClose,
  onRouted,
  onDisqualified,
}: TriggerSchedulingModalProps) {
  try {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      if (!window.ChiliPiper) {
        throw new Error('ChiliPiper was not loaded!');
      }

      // Clear DOM if it exists, this solves if we invoke the modal multiple times with different fields eg there is an error in form submission and the user retries
      emptyDomContainer(domElementId);

      const cleanedEmail = cleanEmail(fields.email);

      if (cleanedEmail === 'force-error@transcend.io') {
        throw new Error('Force error');
      }

      window.ChiliPiper.submit('transcend', 'contact-us', {
        trigger: 'ThirdPartyForm',
        lead: {
          email: cleanedEmail,
          firstName: fields.firstName,
          lastName: fields.lastName,
          company: fields.company,
          state: fields.state,
          country: fields.country,
          companySize: fields.companySize,
        },
        onRouted,
        onDisqualified,
        domElement: domElementId,
        onClose,
      });
    }
  } catch (err) {
    console.error(err);
  }
}
